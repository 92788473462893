<template>
	<div style="background: #fff;padding: 15px;" class="ml-goodList">
		<!-- tab切换 -->
		<el-tabs v-model="listState" @tab-click="goodsFilter" v-if="listStateList[0].num">
			<el-tab-pane
				v-for="item in listStateList"
				:key="item.id"
				:label="item.value + '(' + item.num + ')'"
				:name="item.id+''"
			></el-tab-pane>
		</el-tabs>

		<div class="filter-container">
			<div class="filter-item">
				<label class="label">关键字:</label>
				<el-select v-model="searchType" style="width: 120px;margin-right: 10px;">
					<el-option v-for="item in goodsSelect" :key="item.id" :label="item.type" :value="item.id"></el-option>
				</el-select>
				<el-input
					v-model="searchKey"
					placeholder="商品名称、编码、条码"
					style="width:250px;margin-right: 10px;"
					clearable
					@keyup.enter.native="goodsFilter"
				></el-input>
			</div>
            <div class="filter-item">
				<label class="label">商品分组:</label>
				<el-select v-model="groupId" filterable clearable style="width: 200px;margin-right: 10px;">
					<el-option 
                        v-for="item in goodGroupData" 
                        :key="item.groupId" 
                        :label="item.groupName" 
                        :value="item.groupId">
                    </el-option>
				</el-select>
			</div>
            <div class="filter-item">
				<label class="label">创建时间:</label>
				<el-date-picker
					v-model="searchTime"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="～"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="yyyy-MM-dd HH:mm:ss"
				></el-date-picker>
			</div>
		</div>
		<div class="filter-container">
			<div class="filter-item">
				<el-button type="primary" @click="goodsFilter">查询</el-button>
				<buttonPermissions :datas="'addProduct'">
					<el-button type="primary" @click="eidtGroup({})" style="margin-left:10px">发布商品</el-button>
				</buttonPermissions>
				<buttonPermissions :datas="'downloadProduct'">
					<el-button @click="exportFun" style="margin-left:10px"  :loading='exportLoading'>导出</el-button>
				</buttonPermissions>
			</div>
		</div>
		<!-- 表格 -->
		<div class="table-container">
			<el-table
				:data="goodsData"
				style="width: 100%;"
				v-loading="loading"
				ref="multipleTable"
				empty-text="查询不到相关商品"
			>
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column label="商品" width="350" prop="goodsName">
					<template slot-scope="scope">
						<div class="product-info" style="align-items:flex-start">
							<img
								v-if="scope.row.thumbImgUrl"
								:src="scope.row.thumbImgUrl+'?x-oss-process=image/resize,m_fixed,h_80,w_80'"
							/>
							<svg-icon v-else icon-class="noImgIcon" />
							<div style="min-height: auto;">
								<div style="display:flex;justify-content:space-between;align-items:flex-start;">
									<div style="width:220px;">
										<pre
											style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;"
										>{{scope.row.goodsName}}</pre>
									</div>
								</div>
								<div style="color:#909399">{{scope.row.goodsCode}}</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="访问量">
					<template slot-scope="scope">
						<div>访客数:{{scope.row.visitUserCount}}</div>
						<div>浏览量:{{scope.row.visitCount}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="onOffShelfTime" label="上下架时间"></el-table-column>
				<el-table-column label="上架状态">
					<template slot-scope="scope">
						<buttonPermissions :datas="'reviseProduct'" v-if="scope.row.shelfStatus == 0">
							<el-switch
								v-model="scope.row.shelfStatus"
								active-color="#13ce66"
								inactive-color="#ff4949"
								@change="displyFun(scope.row)"
							></el-switch>
						</buttonPermissions>
						<buttonPermissions :datas="'reviseProduct'" v-else-if="scope.row.shelfStatus == 1">
							<el-switch
								v-model="scope.row.shelfStatus"
								active-color="#13ce66"
								inactive-color="#ff4949"
								@change="displyFun(scope.row)"
							></el-switch>
						</buttonPermissions>
						<span>{{scope.row.shelfStatus == 0?'已下架':'已上架'}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="salePrice" label="价格">
					<template slot-scope="scope">
						<div style="display:flex;justify-content:flex-start;align-items:center;">
							<div style="white-space: nowrap;margin-right: 5px;color:#F56C6C;">￥{{scope.row.salePrice}}</div>
							<buttonPermissions :datas="'updateGoodsPrice'">
								<i class="el-icon-edit-outline" @click="goodsEdit(scope.row,1)"></i>
							</buttonPermissions>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="totalStock" label="库存"></el-table-column>
				<el-table-column label="操作" >
					<template slot-scope="scope">
						<buttonPermissions :datas="'editGoodDetails'">
							<el-button type="text" @click="eidtGroup(scope.row)" class="pointers">编辑</el-button>
						</buttonPermissions>
						<button-permissions :datas="'goodsvoucherTg'">
							<div type="text" class="pointers" style="color: #409EFF;" @click="handleTablePromote(scope.row.id)">推广</div>
						</button-permissions>
						<buttonPermissions :datas="'deleteProduct'" v-if="scope.row.shelfStatus == 0">
							<el-button
								type="text"
								@click="delGoods(scope.row)"
								class="pointers"
								style="color:#f56c6c;"
							>删除</el-button>
						</buttonPermissions>
					</template>
				</el-table-column>
			</el-table>
			<div>
				<el-pagination
					v-if="total"
					style="margin:20px 0;float:right;"
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="pageNo"
					:page-sizes="[10, 20, 30, 40, 50]"
					:page-size="pageSize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="total"
				></el-pagination>
			</div>
		</div>

		<!-- 商品价格编辑的弹框 -->
		<el-dialog
			:visible.sync="editPriceVisable"
			width="800px"
			:title="dialogTitle"
			v-loading="specLoading"
		>
			<el-form :model="ruleForm" ref="ruleForm">
				<div class="setPricebos" v-if="pricestocktype == 1 || pricestocktype == 2">
					<el-table :data="ruleForm.specData" border style="width: 100%;">
						<el-table-column
							:prop="goodsSpec.specName"
							:label="goodsSpec.specName"
							v-for="(goodsSpec,index) in this.goodSpecsList"
							:key="index"
						></el-table-column>
						<el-table-column label="价格" v-if="pricestocktype == 1">
							<template slot-scope="scope">
								<el-form-item :inline-message="true" prop="salePrice">
									<el-input
										clearable
										v-model="scope.row.salePrice"
										style="width:100px;"
										onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"
									></el-input>
								</el-form-item>
							</template>
						</el-table-column>
						<el-table-column label="库存" v-if="pricestocktype == 2">
							<template slot-scope="scope">
								<el-form-item :inline-message="true" prop="stock">
									<el-input
										clearable
										v-model="scope.row.stock"
										style="width:100px;"
										onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"
									></el-input>
								</el-form-item>
							</template>
						</el-table-column>
					</el-table>
				</div>
                <div v-else>
                    <el-form-item
						label="虚拟销量："
						:inline-message="true"
						prop="virtualSoldCount"
						:rules="[{required:true,message:'虚拟销量不能为空',trigger: 'blur'}]"
					>
                        <el-input
							v-model="ruleForm.virtualSoldCount"
							style="width:180px;"
							@input="formatParseInt(ruleForm,'virtualSoldCount')"
						></el-input>
					</el-form-item>
                </div>
				<div style="text-align: center;margin-top:30px;">
					<el-button
						type="primary"
						style="width: 120px;"
						:loading="loading"
						@click="savePriceBtn('ruleForm')"
					>保存</el-button>
				</div>
			</el-form>
		</el-dialog>
		<!-- 推广的弹框 -->
		<batch-cods :weiCodeVisivble="weiCodeShow" :weiCodeData="weiCodeData" @cancel="weiCodeCancel"></batch-cods>
	</div>
</template>

<script>
import {
	getCpsGoodsList,
	productOpen,
	productInfo,
	productDel,
	productChangePrice,
	productChangeSoldCount,
	productSpeChangeStock,
	cpsGoodsCount,
	cpsGoodsExport,
    productGroupListData,
} from '@/api/goods'
import buttonPermissions from '@/components/buttonPermissions';
import batchCods from "@/components/bathCodes"
export default {
    name: 'goodsList',
	components: {
		buttonPermissions,
		batchCods
	},
	data () {
		return {
			specLoading: false,
			ruleForm: {
				name: '',       //名称
				code: '',       //编码
                specData: [],   //规格数据
                virtualSoldCount:0, //虚拟销量
			},
			goodsSelect: [{ //关键字类型
				id: 0,
				type: '商品名称'
			},
			{
				id: 1,
				type: '商品编码'
			},
			{
				id: 2,
				type: '商品条码'
			}],
			listStateList: [    //tab页标题
				{
					id: 0,
					value: '全部',
					num: 0
				},
				{
					id: 1,
					value: '销售中',
					num: 0
				},
				{
					id: 2,
					value: '仓库中',
					num: 0
				},
				{
					id: 3,
					value: '已售罄',
					num: 0
				},
				{
					id: 4,
					value: '警戒',
					num: 0
				}
			],
			listState: '0',     //tab页下标
            goodsData: [],      //商品数据
            goodGroupData:[],   //分组数据
			pageNo: 1,
			pageSize: 20,
			total: 3,
			searchKey: '',  //搜索关键字
			searchType: 0,  //关键字类型
			groupId: '', //商品分组
			loading: false,
			editPriceVisable: false, //是否显示价格编辑弹窗
			priceEditdata: {},  //当前编辑的商品
			pricestocktype: null,   //修改数据类型
			searchTime: '',    //查询时间段
			pickerOptions: {        //时间区间提示
				shortcuts: [{
					text: '最近一周',
					onClick (picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick (picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick (picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			dialogTitle: '', //弹窗标题
			goodSpecsList: '',   //规格名称列表
			weiCodeShow: false,
            weiCodeData: {},
            exportLoading:false,    //导出按钮样式
		};
	},

	beforeMount () {
        this.getList();
        this.getGroupList();
	},
	methods: {

		// 获取商品列表
		async getList () {
			try {
				this.loading = true
				let data = {
					goodsStatus: this.listState,    //0： 全部；1：出售中；2 仓库中；3：已售罄，4：警戒
					groupId: this.groupId,  //分组ID
					pageNo: this.pageNo, // 页数
                    pageSize: this.pageSize, // 取的数据
				}
				if (this.searchType == 0) {
					data.goodsName = this.searchKey;
				} else if (this.searchType == 1) {
					data.goodsCode = this.searchKey; //编码
				} else if (this.searchType == 2) {
					data.barCode = this.searchKey;  //条码
				}
				if (this.searchTime) {
					console.log(this.searchTime);
					data.startDate = this.searchTime[0];
					data.endDate = this.searchTime[1];
				}
				let result = await getCpsGoodsList(data);
				this.total = result.data.total;
				this.goodsData = result.data.records;
				this.goodsData.map(item => {
					item.shelfStatus = item.shelfStatus == 1 ? true : false;
				})

				//获取商品统计数据
				let result2 = await cpsGoodsCount();
				this.listStateList[0].num = result2.data.totalCount;
				this.listStateList[1].num = result2.data.onSaleCount;
				this.listStateList[2].num = result2.data.offSaleCount;
				this.listStateList[3].num = result2.data.soldOutCount;
				this.listStateList[4].num = result2.data.warningCount;
			} catch (e) {
				console.log(e)
			} finally {
				setTimeout(() => {
					this.loading = false
				}, 500)
			}
		},
		// 搜索
		goodsFilter () {
			this.pageNo = 1;
			this.getList();
		},
		// 导出
		async exportFun () {
            this.exportLoading = true;
			let data = {
				goodsStatus: this.listState,    //0： 全部；1：出售中；2 仓库中；3：已售罄，4：警戒
				groupId: this.groupId,  //分组ID
				pageNo: this.pageNo, // 页数
                pageSize: this.pageSize, // 取的数据
                isDownload: true,    //下载文件标记
			}
			if (this.searchType == 0) {
				data.goodsName = this.searchKey;
			} else if (this.searchType == 1) {
				data.goodsCode = this.searchKey; //编码
			} else if (this.searchType == 2) {
				data.barCode = this.searchKey;
			}
			if (this.searchTime) {
				console.log(this.searchTime);
				data.startDate = this.searchTime[0];
				data.endDate = this.searchTime[1];
			}
			await cpsGoodsExport(data);
			this.$message({
                type: 'success',
                message: '导出成功，请稍后至下载中心查看!'
            });
            setTimeout(()=>{
                this.exportLoading = false;
            },300)
            
		},

		// 切换显示条数
		handleSizeChange (val) {
			this.pageSize = val;
			this.getList();
		},
		// 翻页
		handleCurrentChange (val) {
			this.pageNo = val;
			this.getList();
		},

		// 编辑商品数据
		async goodsEdit (item, type) {
			//type: 1修改价格，2修改库存，3修改实际销量，4修改虚拟销量
            let dialogTitle = '';
            this.ruleForm.virtualSoldCount = 0;
			switch (type) {
				case 1:
					dialogTitle = '修改价格';
					break;
				case 2:
					dialogTitle = '修改库存';
					break;
				case 3:
                    dialogTitle = '修改虚拟销量';
                    this.ruleForm.virtualSoldCount = item.virtualSoldCount;
					break;
			}
			this.dialogTitle = dialogTitle;
			this.priceEditdata = item;
			this.pricestocktype = type;
			await this.getDataEdit();
			this.editPriceVisable = true;
		},

		async getDataEdit () {
			try {
				this.specLoading = true
				let result = await productInfo({
					id: this.priceEditdata.id
				})
				let data = result.data;
				//规格名
				let goodSpecsList = data.goodSpecsList;
				this.goodSpecsList = goodSpecsList;
				//规格明细
				let skuResponseList = data.skuResponseList;
				//组织规格明细数据
				this.ruleForm.specData = [];
				skuResponseList.map(skuResponse => {
					let temp = {}
					goodSpecsList.map((goodSpecs, index) => {
						temp[goodSpecs.specName] = skuResponse.specValues[index];
					})
					temp.salePrice = skuResponse.salePrice;
					temp.stock = skuResponse.stock;
					temp.skuId = skuResponse.skuId;
					temp.virtualSoldCount = skuResponse.virtualSoldCount;
					this.ruleForm.specData.push(JSON.parse(JSON.stringify(temp)));
				})
				console.log(this.ruleForm.specData);
			} catch (e) {
				console.log(e)
			} finally {
				this.specLoading = false
			}
		},
		// 保存编辑弹窗中的内容
		savePriceBtn (formName) {
			this.$refs[formName].validate(async (valid) => {
				if (valid) {
					try {
						let data = {
							goodsId: this.priceEditdata.id,
							skuQuickEditRequestList: []
						}
						//处理规格数据
						this.ruleForm.specData.map(item => {
							data.skuQuickEditRequestList.push({
								salePrice: item.salePrice,
								skuId: item.skuId,
								stock: item.stock,
								virtualSoldCount: 0
							});
                        })
                        data.skuQuickEditRequestList[0].virtualSoldCount = this.ruleForm.virtualSoldCount;
						let result = '';
						switch (this.pricestocktype) {
							case 1:
								result = await productChangePrice(data);
								break;
							case 2:
								result = await productSpeChangeStock(data);
								break;
							case 3:
								result = await productChangeSoldCount(data);
								break;
						}

						if (result.success) {
							this.$message({
								type: 'success',
								message: '操作成功!'
							});
							this.editPriceVisable = false;
						}

					} catch (e) {
						console.log(e)
					} finally {
						this.getList();
					}

				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},

		//编辑
		eidtGroup (item) {
			this.isShowEdit = !this.isShowEdit
            // let temp = item.id ? ('?id=' + item.id) : '';
            // this.$router.push({ path: '/goods/editGoodDetails' + temp })
            let params = {
                id: item.id
            }
            this.publicToDetails(params,'/goods/cps/cpsGoodsDetail',this);
            
		},

		// 上下架
		async displyFun (record) {
			this.loading = true;
			try {
				let data = {
					goodsId: record.id,
					status: record.shelfStatus ? 1 : 0
				}
				let result = await productOpen(data)
				if (result.success) {
					this.$message({
						type: 'success',
						message: '操作成功!'
					});
				}
			} catch (error) {
				console.log(error);
			} finally {
				this.loading = false;
				this.pageNo = 1;
				this.getList();
			}
		},
		//删除
		delGoods (row) {
			this.$confirm('删除后不可恢复，是否确认确认继续删除?', '提示', {}).then(async () => {
				let goodsIds = [row.id]
				let result = await productDel(goodsIds);
				if (result.success) {
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
					this.getList();
				} else {
					this.$message({
						type: 'error',
						message: result.alertMsg
					});
				}
			})
		},
		// 推广-商品
		handleTablePromote (id) {
			this.weiCodeShow = true;
			// let uid = this.getRamNumber()
			this.weiCodeData = {
				Page: `pages/detail/detail`,
				Scene: `id=${id}`
			}
		},
		weiCodeCancel (val) {
			console.log(val);
			this.weiCodeShow = false;
        },
        // 获取分组列表数据
		async getGroupList () {
			try {
				this.loading = true
				let data = {
					pageNo: 0, // 略过数据
					pageSize: 10000, // 取的数据
				}
				let result = await productGroupListData(data);
				this.goodGroupData = result.data.records || [];
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
        },
	}
};
</script>

<style lang="less">
.virtualsalesBox {
	/deep/.el-dialog__body {
		padding: 0 20px 10px !important;
	}
}

.nameboxClass {
	.el-dialog__body {
		padding: 0;
	}
}

.ml-goodList {
	.product-info > img {
		width: 80px;
		height: 80px;
	}

	.setPricebos {
		.el-form-item {
			margin-bottom: 0;
		}
	}

	.el-dialog__body {
		padding: 10px 20px !important;
	}

	.bottom_delete {
		border: 1px solid #f5f5f5;
		margin-left: 10px;
		padding: 5px 13px;
	}

	input[type="number"]::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	.number__input {
		border: 1px solid #ccc;
	}

	.pointers {
		cursor: pointer;
		margin-right: 10px;
	}

	.sorts-names {
		max-height: 400px;
		overflow: auto;
		margin-top: 20px;
	}

	.sorttable-top {
		width: 100%;
		background: #f8f8f9;
		padding: 15px;
	}

	.sortTable-body {
		width: 100%;
		padding: 15px;
	}
}
</style>
